<template>
  <base-content-page :page-title="pageTitle" :caption="pageDescription">
    <div class="text-center">
      <v-btn nuxt link x-large color="accent" to="/">Return to homepage</v-btn>
      <v-img
        max-width="600px"
        class="mx-auto mb-5 mt-5"
        contain
        min-height="400px"
        alt="Help, the page was not found"
        :src="require('@/assets/illustrations-bg-light/Asset 13.svg?inline')"
      />
    </div>
  </base-content-page>
</template>

<script>
import BaseContentPage from '@/components/BaseContentPage'

export default {
  components: {
    BaseContentPage
  },

  data: () => ({
    pageTitle: 'Page Not Found',
    pageDescription: 'The page you are looking for was not found'
  }),

  head() {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageDescription
        }
      ]
    }
  }
}
</script>
